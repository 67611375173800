import { API } from '_js/store/constants';
import { getBrandAPIRoot } from '_js/brands';
import { isDevelopment } from '_js/utils/environment';

export const FETCH_MULTIPLE_APPLICATIONS = 'API/APPLICATION/FETCH_MULTIPLE_APPLICATIONS';
export const FETCH_MULTIPLE_APPLICATIONS_SUCCESS =
  'API/APPLICATION/FETCH_MULTIPLE_APPLICATIONS_SUCCESS';
export const FETCH_MULTIPLE_APPLICATIONS_FAILED =
  'API/APPLICATION/FETCH_MULTIPLE_APPLICATIONS_FAILED';
export const APPLICATIONS_OVERVIEW = 'APPLICATIONS_OVERVIEW';

const success = (type, payload) => ({
  type,
  payload,
});

const failure = (type, error) => ({
  type,
  message: 'Failed to fetch applications.',
  error,
});

export const setApplicationsOverview = (isShowingMultipleApplications) => ({
  type: APPLICATIONS_OVERVIEW,
  payload: isShowingMultipleApplications,
});

export const getMultipleApplications = (customerId, brand) => ({
  type: API,
  types: [
    FETCH_MULTIPLE_APPLICATIONS,
    FETCH_MULTIPLE_APPLICATIONS_SUCCESS,
    FETCH_MULTIPLE_APPLICATIONS_FAILED,
  ],
  payload: () => ({
    url: `/applications/history/${customerId}?brand=${brand}`,
    method: 'GET',
    success,
    failure,
    auth: true,
  }),
  axios: {
    baseURL: getBrandAPIRoot(),
    withCredentials: !isDevelopment(),
  },
});
